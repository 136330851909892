.message {
  display: flex;
  align-items: center;
  position: relative;
  width: fit-content;
  justify-content: space-between;
  margin: 15px;
}

.message-sender {
  margin-left: auto;
}

.message-sender > .message-photo {
  order: 1;
  margin: 15px;
}

.message-photo {
  order: 0;
}

.message-sender > p {
  background-color: #3cabfa !important;
  color: white;
}

.message > p {
  background-color: #f3f3f5;
  font-size: medium;
  padding: 15px;
  border-radius: 20px;
  margin: 10px;
  margin-right: auto;
}

.message > small {
  position: relative;
  color: gray;
  bottom: -5px;
  right: 0px;
}
