.sidebar {
  flex: 0.35;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #f5f5f5;
  border-right: 1px solid lightgray;
}

.sidebar-header {
  display: flex;
  align-items: center;
  padding: 10px;
  height: 50px;
}

.sidebar-avatar {
  cursor: pointer;
  margin: 10px;
}

.sidebar-input {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  background-color: #e1e1e1;
  color: gray !important;
  border-radius: 5px;
}

.sidebar-input > input {
  border: none;
  background: transparent;
  outline-style: solid;
  outline-width: 0;
}

.sidebar-chats {
  overflow: scroll;
}

/* Hide scrollbar for chrome, safari and Opera */

.sidebar-chats::-webkit-scrollbar {
  display:none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.sidebar-chats {
  -ms-overflow-style: none; /*For IE and Edge*/
  scrollbar-width: none; /*Firefox*/
}
