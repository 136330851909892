.login {
  display: grid;
  place-items: center;
  height: 100vh;
  width: 100%;
}

.login > div > img {
  object-fit: contain;
  height: 150px;
}

.login > button {
  width: 300px;
  background-color: #3ea4fb;
  color: #eff2f5;
  font-weight: 500;
}

.login > button:hover {
  background-color: gainsboro;
  color: #3ea4fb;
}