.chat {
  display: flex;
  flex-direction: column;
  flex: 0.65;
  height: 100vh;
  background-color: white;
}

.chat-header {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid lightgray;
  background-color: #f5f5f5;
}

.chat-header > h4 {
  font-weight: 500;
  color: gray;
}

.chat-name {
  color: black;
}

.chat-messages {
  flex: 1;
  overflow: scroll;
}

.chat-messages::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.chat-messages {
  -ms-overflow-style: none;
  /*For IE and Edge*/
  scrollbar-width: none;
  /*Firefox*/
}

.chat-input {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  border-top: 1px solid lightgray;
  background-color: #f5f5f5;
}

.chat-input > form {
  flex: 1;
}

.chat-input > form > input {
  width: 98%;
  outline-width: 0;
  outline-style: solid;
  border: 1px solid lightgray;
  padding: 5px;
  border-radius: 999px;
}

.chat-input> form > button {
  display: none;
}